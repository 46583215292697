.cv-download-button {
    padding: 10px;
    background-color: darkgrey;
    color: black;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .cv-download-button:hover {
    background-color: #a4f1c2;
    transition: 300ms;
  }

  .cv-download-button.pdf-download-in-progress {
    display: none;
  }