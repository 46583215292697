.header {
  position: relative;
  /* height: 10%; */
  width: 100%;
  background-color: black;
  box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
  z-index: 2;
}

.header .active,
.header a:hover,
.header i:hover {
  color: orange;
  text-decoration: none;
  transition: 300ms;
}

.logo {
  font-family: Arial, sans-serif;
  font-size: 60%;
  color: rgba(245, 245, 245);
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  background-color: transparent;
}
.circle-logo {
  width: 50px;
  border-radius: 50%;
  background-color: #fd6050;
  background-image: radial-gradient(
      rgba(253, 96, 1, 1) 9%,
      rgba(7, 58, 255, 0) 100%
    ),
    linear-gradient(125deg, black 1%, #1a005e 100%);
  margin: 0 auto;
}
.letter-logo {
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}

li {
  text-decoration: none;
  list-style: none;
  font-size: 100%;
  margin: 0 3%;
}

li a {
  color: white;
  font-weight: bold;
}

.nav .ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
  margin-left: 6%;
}

.container {
  max-width: 100%;
  margin: auto;
  display: flex;
}

.ul li {
  display: flex;
  justify-content: flex-end;
}

.nav {
  width: 100%;
  display: flex;
  align-items: right;
  padding-top: 1.3%;
}

.socials {
  width: 14%;
  margin-left: 6%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5rem;
}

.socials i {
  color: white;
}


.mobile-menu-icon {
  display: none; /* Asegura que el botón del menú no sea visible en pantallas grandes */
  margin-top: -1%;
  align-items: center;
}

@media screen and (max-width: 830px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    display: none;
    width: 100%;
  }

  .nav-mobile {
    position: absolute;
    list-style: none;
    right: 0;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .ul {
    width: 100%;
    margin-top: 3%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-menu-icon {
    display: flex;
    background: #fd6050;
    color: black;
    font-size: 20px;
    cursor: pointer;
    margin: 1.5% 75%;
  }

  li a {
    color: black;
    margin: 0.25em 0;
  }

  .socials {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
  }

  .socials i {
    color: black;
  }
}
