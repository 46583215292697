.single-project-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 80vh; 
}
  
  .single-project-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  
  .single-project-mask {
    width: 40%;
    display: flex;
    justify-content: center;
    margin-bottom: 0 10px;
    margin-right: 20px;
  }
  
  .single-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }
  
  .single-image:hover {
    width: 120%;
  }

  .video-container {
    width: 40%;
    display: flex;
    justify-content: center;
    margin: 0 10px;
    margin-left: 20px;
  }
  
  video {
    width: 100%;
    height: auto;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  video:hover {
    width: 120%;
  }

  button {
    margin-top: 5%;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }
  
  button:hover {
    background-color: #0056b3;
    font-size: 1.5rem;
    font-weight: bold;
  }  

  @media (max-width: 830px) {
    .single-project-details {
      flex-direction: column;
      align-items: center;
    }
  
    .single-project-mask {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
    }

    .single-image:hover {
      width: 108%;
    }
  
    .video-container {
      width: 100%;
      margin: 0;
      margin-left: 0;
    }

    video:hover {
      width: 108%;
    }

    button {
      margin-bottom: 7%;
    }
  }