.projects-container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 2rem;
  text-align: center;
  color: whitesmoke;
  margin-bottom: 2rem;
}

.projects-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  margin-left: 4rem;
}

.work-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem; /* Espacio entre proyectos */
}

.mask-image {
  width: 33.33%;
  height: 12rem;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 14px #ddd;
}

.work-item img {
  width: 100%;
  height: auto;
  transition: all 0.5s ease-in-out;
}

.mask-image:hover img {
  width: 120%;
}

.project-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-left: 5rem;
    width: 60%;
    height: auto;
}

.work-item span,
.work-item h2,
.work-item h3,
.work-item p {
  color: black;
}

.projects-header {
  font-size: 3rem;
  font-weight: 700;
  color: black;
  padding-top: 3rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.work-item a {
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.work-item a:hover {
  color: blue;
}

.work-item p {
  font-size: 1.2rem;
}

.link img {
  width: 1.2rem;
  margin-left: 1.8rem; 
  margin-bottom: .3rem;
  background-color: #fff;
  border-radius: 4px; 
}

.link a {
  font-size: 1.5rem;
  font-weight: bold;

}

.link a:hover {
  font-size: 2rem;
  transition: all 0.3s ease-in-out; 
}

.link a:hover .github-icon {
  width: 1.5rem; 
  height: 1.5rem; 
  font-size: 2rem;
  transition: all 0.3s ease-in-out; /* Agrega transición para el tamaño */
}

.tech-link-img {
  display: flex;
  justify-content: space-between;
  align-items: center;  
}

.project-details h3 {
  display: flex;
  margin-top: .5rem;
  font-size: 1rem;
  text-align: left;
  align-items: center;
}

@media (max-width: 830px) {
  .projects-header {
    padding-top: 1rem;
  }

  .work-item {
    flex-direction: column-reverse; /* Cambia a column-reverse en pantallas pequeñas */
  }

  .mask-image,
  .project-details {
    width: 100%;
    height: auto; 
    margin-bottom: 1.5rem; /* Espacio entre proyectos en pantallas pequeñas */
    margin-left: -5rem;
  }

  .link {
    position: relative; 
    
  }

  .project-details h3 {
    margin-top: .3rem;
  }

  .tech-link-img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 1400px) {
  .mask-image {
    width: 90%;
    height: auto; 
    margin-bottom: 1.5rem; /* Espacio entre proyectos en pantallas pequeñas */
    margin-left: -5rem;
  }
}