* {
    margin: 0;
    padding: 0;
}

#pagina-especial {
    margin-top: -7%;
}


