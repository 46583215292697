#pagina-especial {
  margin-top: 0%;
  padding: 0%;  
  background-image: url('../images/background-image.png'); /* Ruta a la imagen de fondo */
  background-size: 140% auto; /* Ajusta la imagen de fondo al tamaño del contenedor */
  background-position: center; /* Centra la imagen de fondo */
}

.hero-home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #ccc; */
}

.hero-head {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  color: #f2f2f2;
  font-size: 2.8rem;
  margin-top: 3%;
}

.blink-cursor {
  margin-left: 10px;
  margin-right: 2px;
  color: transparent;
}

.blink-cursor.active {
  color: orange;
}

.socials-icons-home {
  display: flex;
  gap: 8rem;
  justify-content: center;
  font-size: 5rem;
  padding: 1rem;
}

.socials-icons-home i {
  color: #f2f2f2;
  text-decoration: none;
  transition: 300ms;
}

.socials-icons-home i:hover {
  color: orange;
}

.hero-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: auto;
  padding: 1rem;
  text-align: center;
  margin-top: 1rem;
}

.fa-brands-home {
  color: #0c0c0c99;
  font-size: 3rem;
}

.hero-links a {
  display: block;
  background-color: #f2f2f2;
  border: 1px solid #c1c1c1;
  padding: 1rem;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-left: 100px;
  margin-right: 100px;  
  border-radius: 5%;
}

.hero-links a:hover {
  background-color: #a4f1c2;
  transition: 300ms;
}

@media screen and (max-width: 830px) {

  #pagina-especial {
    background-size: 350% auto; /* Ajusta la imagen de fondo al tamaño del contenedor */
    background-position: center; /* Centra la imagen de fondo */
  }
  
  
  .hero-head {
    font-size: 1rem;
  }

  .socials-icons-home {
    font-size: 3rem;
    display: flex;
    align-items: center;
  }

  .hero-links {
    display: flex;
    flex-direction:column;
    justify-content: center;
    margin-top: 0;
  }

} 
