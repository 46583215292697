.main-container {
  width: 100%;
}

.cv-container {
  display: flex;
  background-color: #ccc;
}

.sidebar {
  width: 40%;
  background-color: #e0e0e0;
  padding-top: 5.5%;
  /* padding-left: 2%; */
  margin-right: 20px;
}

.cv-sidebar-contact {
  padding: 10px;
}

.cv-sidebar-contact p a {
  color: black;
}

.languages {
  padding: 10px;
}

.resumen {
  padding: 10px;
}

.cv-container .lierni {
  max-width: 100%;
  max-height: 200px;
  margin: 0 25%;
  display: block;
  border-radius: 50%;
}

.skills .icon-image {
  margin-right: 30px;
}

.main-content {
  width: 100%;
  /* padding-top: 3.5%; */
  padding-left: 2%;
  margin-right: 20px;
  margin-top: -2%;
  margin-bottom: 3%;
  color: black;
}

.head-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.curriculum-page p {
  font-size: 20px;
}

hr {
  width: 100%;
  border-top: 4px solid #f2f2f2;
}

.skills-container {
  display: grid;
  max-width: 100%;
  margin-top: 2%;
  color: black;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  text-align: center;
  font-size: 20px;
}

.skill-icon-front,
.skill-icon-back,
.skill-icon-data,
.skill-icon-addition,
.skill-icon-cybersecurity {
  display: grid;
  justify-content: center;
  justify-items: start;
  font-size: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
  align-content: space-evenly;
  justify-content: space-evenly;
}

.skill-item-front,
.skill-item-back,
.skill-item-data,
.skill-item-addition,
.skill-item-cybersecurity {
  font-size: 30px;
  margin-bottom: 15px;
}

.skill-item-front {
  grid-column: 1 / 2;
  grid-row: 1/ 2;
}

.skill-item-back {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.skill-item-data {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

.skill-item-addition {
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

.skill-item-cybersecurity {
  grid-column: 5 / 6;
  grid-row: 1 / 2;
}

.skill-icon-front {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  text-align: start;
}

.skill-icon-back {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  text-align: start;
}

.skill-icon-data {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  text-align: start;
}

.skill-icon-addition {
  grid-column: 4 / 5;
  grid-row: 2 / 3;
  text-align: start;
}

.skill-icon-cybersecurity {
  grid-column: 5 / 6;
  grid-row: 2 / 3;
  text-align: start;
}

.icons {
  transition: all 0.2s ease-in-out;
  display: flex;
}

.icons:hover {
  transform: scale(1.1);
}

.icon-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 15px;
}

@media (max-width: 830px) {
  .sidebar {
    margin-bottom: 5%;
    width: 100%;
  }

  .sidebar p {
    font-size: 80%;
  }

  .cv-container .lierni {
    max-width: 50%;
  }

  .cv-sidebar-contact {
    width: 100%;
  }

  .cv-sidebar-contact h2,
  .languages h2,
  .resumen h2 {
    font-size: 15px;
    font-weight: bold;
  }

  .cv-sidebar-contact p {
    margin-bottom: 12px;
    word-break: break-all;
  }

  .head-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .curriculum-page p {
    font-size: 14px;
  }

  .name {
    margin-top: 25px;
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  .cv-download-button {
    font-size: 15px;
    margin-bottom: 5px;
  }

  h5 {
    font-size: 14px;
  }

  .education p,
  .otros-certificados p {
    font-size: 13.6px;
  }

  .skills .icon-image {
    margin-right: 50px;
  }

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    width: 200px;
  }

  .skill-item-front,
  .skill-item-back,
  .skill-item-data,
  .skill-item-addition,
  .skill-item-cybersecurity {
    font-size: 18px;
    font-weight: bold;
  }
}

@media (min-width: 831px) {
  .sidebar {
    padding-top: 2%;
    width: 40%;
    padding-left: 3%;
  }


  .cv-sidebar-contact {
    width: 100%;
    margin-top: 10%;
  }

  .main-content {
    padding-top: 3%;
    padding-right: 3%;
  }  
}