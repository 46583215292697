.about-container {
  margin: 0 auto;
  max-width: 100%;
}

.about-content {
  padding: 0 4em;
  padding-top: 4%;
  padding-bottom: 0;
  margin-bottom: 30px;
  /* align-items: center; */
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  justify-items: center;
  grid-column-gap: 100px;
  animation: about-content-animation 3s ease-in-out;
}

@keyframes about-content-animation {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.my-name {
  color: rgba(0, 0, 0);
  padding: 10px 20px;
  position: relative;
  z-index: 1;
}

.my-name h1 {
  font-size: 30px;
  font-weight: 1000;
}

.my-name p {
  font-size: 20px;
}

.my-name b {
  color: rgba(253, 96, 1, 1);
  text-transform: uppercase;
  font-weight: 1000;
}

.my-name a {
  padding: 5px;
  border-radius: 5px;
  color: rgba(255, 255, 255);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  text-transform: uppercase;
  background-color: rgba(253, 96, 1, 1);
  text-decoration: none;
  transition-property: background-color 300ms ease-in-out,
    font-weight 300ms ease-in-out, font-size 300ms ease-in-out;
}

.my-name a:hover {
  background-color: rgba(152, 255, 152, 1);
  color: rgba(253, 96, 1, 1);
  font-weight: 1000;
  font-size: 18px;
}

.image-right {
  position: relative;
  margin-top: 50px;
}

.image-right img {
  border-radius: 1.7em;
  height: 25em;
  width: 30em;
}

@media (max-width: 830px) {
  .about-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 15%;
    padding-top: 7%
  }


  .about-content .my-name h1 {
    font-size: 20px;
  }

  .about-content .my-name p {
    font-size: 15px;
  }

  .image-right img {
    margin-top: 20%;
    border-radius: 1.7em;
    height: 20em;
    width: 20em;
  }
}

