.contact {
  padding-top: 6%;
  display: flex;
  justify-content: center;
}

.contact-container {
  max-width: 400px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.contact-form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  transition-property: background-color 300ms ease-in-out,
    font-weight 300ms ease-in-out, font-size 300ms ease-in-out;
  background-color: rgba(152, 255, 152, 1);
  color: rgba(253, 96, 1, 1);
  font-weight: 1000;
  font-size: 18px;
}

@media (max-width: 830px) {
  .contact {
    display: flex;
    padding-top: 8%;
  }
}
